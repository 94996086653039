
import { Component, Vue, Watch } from 'vue-property-decorator';
import { User } from '@/models/Entities/User';
import { RoleRouteMap } from '@/models/RoleRouteMap';
import { EUserRoles } from '@/enums';

@Component<HomePage>({})
export default class HomePage extends Vue {
  @Watch('currentUser', { immediate: true })
  goToRouteByRole(user: User): void {
    if (user && user.role) {
      const routeName = RoleRouteMap[user.role];
      if (routeName) {
        this.$router.push({ name: routeName });
      }
    }
  }

  get userIsMember(): boolean {
    return this.currentUser
      ? this.currentUser.role === EUserRoles.MEMBER
      : false;
  }

  get currentUser(): User {
    return this.$store.getters['userModule/currentUser'];
  }
}
