import { EUserRoles } from '@/enums';
import Routes from '@/router/Routes';

export const RoleRouteMap: { [key: string]: string } = {
  [EUserRoles.RECRUITER]: Routes.RECRUITER_CURRENT,
  [EUserRoles.UNIT_REPRESENTATIVE]: Routes.UNIT_REPRESENTATIVE_CURRENT,
  [EUserRoles.FLIGHT_CHIEF]: Routes.FLIGHT_CHIEF_RECRUITER_COLLECTION,
  [EUserRoles.THREE_40TH_FTG_JUNIOR_MEMBER]: Routes.STUDENT_DASHBOARD,
  [EUserRoles.THREE_40TH_FTG_SENIOR_MEMBER]: Routes.STUDENT_DASHBOARD,
  [EUserRoles.ADMIN]: Routes.ADMIN_DASHBOARD,
  [EUserRoles.NONE]: Routes.NEW_USER,
  [EUserRoles.A3_SR_LEADER]: Routes.A3_BOARD_CURRENT,
  [EUserRoles.A3_RB]: Routes.A3_BOARD_CURRENT
};
