var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "flex flex-col items-center justify-center bg-gray-200" },
    [
      _c("div", { staticClass: "flex flex-col items-center justify-center" }, [
        _c("h4", { staticClass: "mb-6" }, [_vm._v("Welcome to Skypatch!")]),
        _vm.userIsMember
          ? _c("h5", [
              _vm._v(
                " Please contact your supervisor or a Skypatch administrator to set your role. Once your role has been set, you will be able to access Skypatch. "
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }